import cookie from 'react-cookies';

const authCookieName = 'authToken';
const accessToken = 'ACCESS_TOKEN'; // needs to match API
const csrfToken = 'CSRF_TOKEN'; // needs to match API

export const setAuthToken = (token, remember) => {
  const expiration = 60 * 15; // 15 minutes
  const rememberExpiration = 60 * 60 * 24 * 14; // 2 weeks
  try {
    cookie.save(authCookieName, token, {
      maxAge: remember ? rememberExpiration : expiration,
    });

    return true;
  } catch (err) {
    return false;
  }
};

export const removeAuthToken = () => {
  try {
    // FIXME: domain needs to be set to the domain being accessed
    // need to test on staging and production

    cookie.remove(authCookieName, {
      path: '/',
      domain: 'localhost',
    });
    cookie.remove(accessToken, {
      path: '/',
      domain: 'localhost',
    });
    cookie.remove(csrfToken, {
      path: '/',
      domain: 'localhost',
    });
    return true;
  } catch (err) {
    return false;
  }
};

export const getAuthToken = () => {
  try {
    return cookie.load(authCookieName);
  } catch (err) {
    return undefined;
  }
};
