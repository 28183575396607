/**
 * Client-lib for Ladder's member API. All calls are made for an authenticated user. The auth info
 * must be in the global `window.ladderMemberCenterData`.
 *
 * When a validation error occurs while create/updating a resource, the returned promise will
 * reject with an instance of `ValidationError`.
 */

import { camelizeKeys, decamelizeKeys } from 'humps';
import { getAuthToken } from '../lib/cookie';
import axios from 'axios';
import removeDraftsFromTitle from '../utils/removeDraftsFromTitle';

// class ValidationError extends Error {
//   constructor(validationErrors) {
//     super(`ValidationError: ${JSON.stringify(validationErrors)}`);

//     this.isValidationError = true;
//     this.validationErrors = validationErrors;
//   }
// }

const axiosRequest = (method, path, data = {}, params) => {
  const url = `${process.env.GATSBY_API_URL}${path}`;

  const options = {
    method,
    withCredentials: true,
    headers: {
      'content-type': 'application/json; charset=utf-8',
    },
    params,
  };

  const authToken = getAuthToken();
  if (authToken) {
    options.headers.Authorization = `Bearer ${authToken}`;
  }

  if (data) {
    options.data = data;
  }

  return axios(url, options).then((resp) => {
    if (resp.status === 200) {
      return camelizeKeys(resp.data);
    } else if (resp.status === 422) {
      return camelizeKeys(resp.data).then((validationErrors) => {
        // Promise.reject(new ValidationError(validationErrors))
        Promise.reject(validationErrors);
      });
    } else {
      return Promise.reject(resp);
    }
  });
};

export const getUser = () => axiosRequest('GET', `/api/user`);

export const getOrder = () => axiosRequest('GET', `/api/order`);

export const getOrderHistory = () =>
  axiosRequest('GET', `/api/order/get-orders`);

export const getDealerOrderHistory = () =>
  axiosRequest('GET', `/api/order/get-orders?dealer_orders=true`);

// FIXME: Refactor to react query
export const updateUser = (data) => axiosRequest('PUT', `/api/user/edit`, data);

export const getAddresses = () =>
  axiosRequest('GET', `/api/user/get-addresses/`);

export const getDealerCustomers = () =>
  axiosRequest('GET', `/api/order/get-dealer-customers`);

export const getDealerCommission = () =>
  axiosRequest('GET', `/api/order/dealer-comission`);

export const getOrderByPaymentCode = (paymentCode) =>
  axiosRequest('GET', `/api/order/get-order-with-payment-link/${paymentCode}`);

// FIXME: React query refactor
export const getProductById = (productId) => {
  return axiosRequest(
    'GET',
    `/api/product/${removeDraftsFromTitle(productId)}`
  );
};

// FIXME: React query refactor
export const getOrderById = (orderId) =>
  axiosRequest('GET', `/api/order/${orderId}`);

export const addToCart = (
  sku,
  qty = 1,
  svgIcon = '',
  metadata = [],
  childData = []
) => {
  return axiosRequest('POST', `/api/order/add`, {
    sku,
    qty: Number(qty),
    // svg_icon: svgIcon,
    meta: metadata,
    child: childData,
  });
};

export const applyCommissionAsPayment = (balance, notes) =>
  axiosRequest('POST', `/api/payment/commission-balance`, {
    amount: balance,
    notes,
  });

export const applyCommissionForCustomer = (orderId, balance, notes) =>
  axiosRequest('POST', `/api/payment/commission-balance/${orderId}`, {
    amount: balance,
    notes,
  });

// FIXME: React query refactor
export const doCheckoutPayment = async (
  paymentMethodNonce,
  paymentType,
  notes
) => {
  return await axiosRequest('POST', '/api/payment/braintree', {
    payment_method_nonce: paymentMethodNonce,
    payment_type: paymentType,
    notes,
  });
};

export const saveShippingAddress = (data) =>
  axiosRequest('POST', `/api/order/save-shipping-address`, data);

// FIXME: Remove company option on shipping options endpoint
export const getShippingOptions = () =>
  axiosRequest('GET', `/api/order/shipping-options`);

// FIXME: Need to save correct selected shipping option
export const saveShippingOption = (selectedMethod) =>
  axiosRequest('POST', `/api/order/save-shipping-option`, {
    carrier: selectedMethod?.carrier,
    service_code: selectedMethod?.serviceCode,
    service_name: selectedMethod?.serviceName,
  });

export const saveWaiver = (name) =>
  axiosRequest('POST', `/api/order/create-order-signature`, {
    checkbox: 'on',
    name,
  });

export const submitNoPayment = (notes, optIn = false) =>
  axiosRequest('POST', `/api/order/submit`, {
    notes,
    optIn,
  });

export const applyDiscountCode = (orderId, discountCode) =>
  axiosRequest('POST', `/api/order/${orderId}/add-discount`, {
    discount_codes: discountCode,
  });

export const removeDiscount = (orderId) =>
  axiosRequest('POST', `/api/order/${orderId}/remove-discount`);

export const login = (email, password, rememberMe = false) =>
  axiosRequest('POST', `/api/login`, {
    email,
    password,
    remember: rememberMe,
  });

export const logoutModel = () => axiosRequest('POST', `/api/user/logout`, {});

// FIXME: React query refactor
export const loginFromAdminModel = (token) =>
  axiosRequest('POST', `/api/password/verify-login/${token}`, {});

export const signUp = (registerData) =>
  axiosRequest('POST', `/api/user/create`, registerData);

export const loginGuest = (email, rememberMe = false) =>
  axiosRequest('POST', `/api/login-guest`, {
    email,
    remember: rememberMe,
  });

export const updateQuantity = (itemId, qty) =>
  axiosRequest('POST', `/api/order/item/${itemId}/update-qty`, { qty: qty });

// FIXME: React query refactor
export const contactForm = (body) =>
  axiosRequest('POST', `/api/contact/`, body);

export const contactCoach = async (payload) => {
  const slug = payload.coach.slug.current;
  delete payload.coach;

  return axiosRequest('POST', `/api/coach/contact-coaches/${slug}`, payload);
};

export const orderMergeNotified = () =>
  axiosRequest('POST', '/api/order/set-merged-notified');

export const createBitPayPayment = ({ notes }) =>
  axiosRequest('POST', '/api/payment/bitpay', { notes });

export const getArchiveImage = (params) =>
  axiosRequest(
    'GET',
    '/api/product/archive-image',
    {},
    {
      category: params.category,
      type: params.type,
    }
  );

export const passwordReset = ({ confirmPassword, password, token }) =>
  axiosRequest('POST', `/api/password/usetoken/${token}`, {
    confirmPassword,
    password,
  });
